import React from 'react'
//Grid
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import StarsIcon from '@material-ui/icons/Stars';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
}});

export default function BookDisplay (data)
{
    const classes = useStyles();
    let cardAction = (
          <StarsIcon style={{ color: "#ffc400" }} />
     ) 
    
   
    return (
      <Grid item key={data.id} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={data.Image_Link}
                        title="Image title"
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                            <Link href={data.Amazon_Affiliate_Link}  target="_blank" color="inherit">
                            {data.Title} {(data.Favorite == true)? cardAction:null }
                            </Link>
                            <Typography>
                            {data.Author}
                            <br></br>
                            Read: {data.Timestamp}
                            </Typography>
                        </Typography>
                      </CardContent>

                    </Card>
          </Grid>
    );
}


