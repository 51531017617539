import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
   
    toolbarTitle: {
      flex: 1,
    },
    toolbarSecondary: {
      justifyContent: 'space-between',
      overflowX: 'auto',
    },
    toolbarLink: {
      padding: theme.spacing(1),
      flexShrink: 0,
    },
  }));

  const sections = [
    { title: 'Tech', url: '/Blog/Tech' },
    { title: 'Articles', url: '/Blog/Articles' },
    { title: 'Culture', url: '/Blog/Culture' },
    { title: 'Economics', url: '/Blog/Economics' },
    { title: 'Opinion', url: '/Blog/Opinion' },
    { title: 'Science', url: '/Blog/Science' },
    { title: 'Health & Fitness', url: '/Blog/Health_Fitness' },
    { title: 'Style', url: '/Blog/Style' },
    { title: 'Travel', url: '/Blog/Travel' },
    { title: 'Cars', url: '/Blog/Cars' },
    { title: 'Music', url: '/Blog/Music' }
  
  ];
  
  
  export default function Header(props) {
    const classes = useStyles();
  
    return (
      <React.Fragment>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          ))}
        </Toolbar>
      </React.Fragment>
    );
  }
  
  Header.propTypes = {
    sections: PropTypes.array,
    title: PropTypes.string,
  };