import React,{Component} from 'react';


import {Grid,CardMedia,Link, 
  Typography, Box, Divider, Container,
   Card, CardActionArea, CardContent, CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import AlbumIcon from '@material-ui/icons/Album';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';


import FitnessTable from '../fitness-components/fitness.component'
import Main_Menu from "../menu-components/Main-Menu.component"

const styles = theme => ({
    
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    }
  });

class Interest extends Component
{
  

  render()
  {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline></CssBaseline>
        <Grid container>
            <Grid item sm={12} lg={10}>
              <Container maxWidth="md sm">
                  <Typography component="div" >
                      <Box textAlign="left" m={1} >
                        <h1>INTEREST: <FavoriteIcon/></h1>
                      </Box>
                  </Typography>
                  
                  <Grid container>
                  <Grid item sm={1} />
                    <Grid container item xs={10}>
                      <Typography item container xs={12} variant="h5">Guitar <AlbumIcon/></Typography>
                        <Grid container
                            direction="row"
                            justify="space-around"
                            alignItems="flex-start"
                          >
                          <Card item lg={12}  className={classes.root}>
                            <CardActionArea>
                              <CardMedia
                                className={classes.media}
                                image="https://jtgt-static.b-cdn.net/images/default-social.png"
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                  <Link href="https://www.justinguitar.com/" target="_blank" color="inherit">Justin Guitar</Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                  Justin is the goat. This is where I learn music theory and other genres from jazz standards to improvisation. Justin 
                                  loves what he does and I am still learning from him.
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
        
                          <Card item lg={12}  className={classes.root}>
                            <CardActionArea>
                              <CardMedia
                                className={classes.media}
                                image="https://pickupmusic.com/wp-content/uploads/2018/11/tomo-2.jpg"
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                  <Link href="https://tomovhxtv.vhx.tv/" target="_blank" color="inherit">TOMO FUJITA GUITAR WISDOM</Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                  Tomo is great and is where I am currently learning how to play with soul. He has had a record of teaching at Berklee and luckly now we have an archive
                                  without having to travel the distance. 
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
        
                          
                      </Grid>
                    </Grid>
                  <Grid item sm={1} />
                </Grid>
                  
                  <br></br>
                  <Divider />
                  <br></br>
        
                  <Grid container>
                    <Grid item sm={1} />
                      <Grid item xs={10}>
                        <Typography variant="h5">Fitness <FitnessCenterIcon></FitnessCenterIcon></Typography>
                        <FitnessTable/>
                      </Grid>
                    <Grid item sm={1} />
                  </Grid>
        
                  <br></br>
                  <Divider />
                  <br></br>
              </Container>
            </Grid>
            <Main_Menu></Main_Menu>
        </Grid>
      </React.Fragment>
    )  
  }
}

export default withStyles(styles, { withTheme: true })(Interest);