import React from 'react'
import {BrowserRouter as Router ,browserHistory, Route, Switch, Redirect } from "react-router-dom";

import Home from "./pages/home.component"
import About from "./pages/about.component"
import Blog from "./pages/blog.component"
import BlogPage from "./pages/blog.page.component"
import Library from "./pages/library.component"
import Album from "./pages/album.component"
import Interest from "./pages/interest.component"
import Travel from "./pages/travel.component"
import Work from "./pages/work.component"
import RunningStatsDashboard from "./fitness-components/fitness-stats/runningStatsDashboard"
import error404 from "./error404-components/error404.component"
import Footer from "./footer.component"

import { createMuiTheme, ThemeProvider,makeStyles } from "@material-ui/core/styles";





//Blog Pages
import ArticlePage from "./blog-components/blog-pages/article-page"

 const RouterComponent = () => 
 {
    let [darkmode, setDarkMode] = React.useState(()=>{
      let backgroundValue = localStorage.getItem('backgroundTheme')
      return (backgroundValue !== null) ? JSON.parse(backgroundValue):false
    })

    let theme = createMuiTheme({
      palette: {
        type: darkmode?'dark':'light'
      }
    })

    React.useEffect(()=> {
      console.log(darkmode)
    },[])

    let _redirectToHome = () => {
      return <Redirect to="/"/>
    }

    return (
        <React.Fragment>
          <Router>
          <ThemeProvider theme={theme}>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/about" exact component={About} />
                <Route path="/Library" exact component={Library} />
                <Route path="/Interest" exact component={Interest}/>
                <Route path="/Blog" exact component={Blog}/>
                <Route path="/Album" exact component={Album}/>
                <Route path="/Travel" exact component={Travel}/>
                <Route path="/Work" exact component={Work}/>
                <Route path="/Stats/RunningData" exact component={RunningStatsDashboard}/>
                <Route path="/Blog/Articles" exact component={ArticlePage}/>
                <Route path="/Blog/:Genre/:Title/:id" render={props => <BlogPage {...props.match.params} />}/>
                <Route render={_redirectToHome}/>
            </Switch>
            </ThemeProvider>
          </Router>
      </React.Fragment>
    )
}

export default RouterComponent;



