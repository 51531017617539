// Set the configuration for your app
// TODO: Replace with your project's config object
import * as firebase from 'firebase';

let config = {
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: "johnsontran.tech",
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: "johnsonwebsite-98fa7.appspot.com"
};


if (firebase.app.length === 0) {
    firebase.initializeApp({config});
}

export default firebase;

