import React,{useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Main_Menu from '../menu-components/Main-Menu.component'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
 
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album() 
{
  const classes = useStyles();
  
  useEffect(() => {
    document.title = "Album- Johnson Tran"
})

return(
  <React.Fragment>
            <CssBaseline />
            <Grid container >
              <Grid item sm={12} lg={10}>
                <Container maxWidth="md" >
                <Typography component="div">
                    <Box textAlign="left" m={1} >
                        <h1>ALBUM</h1>
                    </Box>
                    <Box textAlign="justify" m={5}>

                    </Box>
                </Typography>
                </Container>
              </Grid> 
              <Main_Menu/>
            </Grid>
          </React.Fragment>
)
}