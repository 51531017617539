import React, {useEffect, useState} from 'react';


//Material
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Container, Typography, CssBaseline, Accordion, AccordionSummary }  from '@material-ui/core';

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Main_Menu from '../menu-components/Main-Menu.component'
import ProjectCard from '../work-components/project-card'

import firebase from '../../Config/Firebase'

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
  
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
  }));


export default function About()
{
    const classes = useStyles();
    let [libraryData2021, setLibraryData2021] = useState([])
    let [libraryData2020, setLibraryData2020] = useState([])
    let [libraryData2019, setLibraryData2019] = useState([])

    useEffect(() => {
        document.title = "Work - Johnson Tran"
    })

    useEffect(() => {

      firebase.database().ref('Projects').once('value').then(snap=> {
        snap.forEach(data=> {
          let date = data.val().Date.slice(0,4)
          switch(date)
          {
            case "2021":
              setLibraryData2021(prevData => {
                return [
                  ...prevData,
                  data.val()
                ]
              })
              break;
            case "2020":
              setLibraryData2020(prevData => {
                return [
                  ...prevData,
                  data.val()
                ]
              })
              break;
            case "2019":
              setLibraryData2019(prevData => {
                return [
                  ...prevData,
                  data.val()
                ]
              })
              break
          }
          
        })
      })
      },[setLibraryData2021])
    

    return(
        <React.Fragment>
        <CssBaseline />
        <Grid container>
          <Grid item sm={12} lg={10}>
            <Container maxWidth="md" >
              <Typography component="div">
                  <Box textAlign="left" m={1} >
                      <h1>WORK: <EmojiPeopleIcon></EmojiPeopleIcon></h1>
                  </Box>
              </Typography>
              <Typography component="div">
                  <Box textAlign="left" m={1} >
                      <h1>PROJECTS: <DescriptionIcon></DescriptionIcon></h1>
                  </Box>
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>2021</Typography>
                </AccordionSummary>
                  {libraryData2021.map((data) => {
                    return( <ProjectCard data={data} key={data.id}></ProjectCard>)
                  })}
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>2020</Typography>
                </AccordionSummary>
                  {libraryData2020.map((data) => {
                    return( <ProjectCard data={data} key={data.id}></ProjectCard>)
                  })}
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>2019</Typography>
                </AccordionSummary>
                  {libraryData2019.map((data) => {
                    return( <ProjectCard data={data} key={data.id}></ProjectCard>)
                  })}
              </Accordion>
            </Container>
          </Grid> 
          <Main_Menu></Main_Menu>
        </Grid>
      </React.Fragment>
    )
    
}