import React from 'react'
//Grid
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

const fitnessHighlightDisplay = (data) =>
{
    return (
      <React.Fragment>
        <Grid item key={data.id} xs={12} sm={4} >
            <Card className={data.classes.card}>
                <CardContent>
                 <Typography gutterBottom >
                   {data.Title}     
                 </Typography>  

                 <Typography gutterBottom variant="h4">
                   {data.Content}     
                 </Typography> 

                 </CardContent>  
            </Card>
          </Grid>
      </React.Fragment>
    );
}

export default fitnessHighlightDisplay;