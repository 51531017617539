import React from 'react'
import {CssBaseline, Container,Typography, Box,Paper,Divider } from '@material-ui/core';
 


class Content extends React.Component
{
    render()
    {
        return(
        <React.Fragment>
            <CssBaseline/>
            <Divider/>
            <br></br>
            
            <Paper>
                <Typography>
                    Title
                </Typography>
            </Paper>

        </React.Fragment>
        )
    }
}

export default Content