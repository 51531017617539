import { Container, Grid } from '@material-ui/core'
import React from 'react'
import firebase from '../../Config/Firebase'
import BookComponent from '../dumb-components/book.component'
import './current-reads.css'



class CurrentRead extends React.Component
{
    state = {
        currentBooks: []
    }
    
    componentDidMount()
    {
        firebase.database().ref('Library').limitToLast(6).once('value').then(snap=> {
           
            snap.forEach(data=> {
                this.setState({currentBooks: [data.val(), ...this.state.currentBooks]})
            })
          })
    }

    render()
    {
        let { classes } = this.props;
            return (
            <Grid container className="horizontal-wrapper" spacing={2}>
                    {this.state.currentBooks.map(data => {
                    return <BookComponent className="currentBook" classes={classes} id={data.id} Title={data.Title} 
                                    Image_Link={data.Image_Link} 
                                    Author={data.Author} 
                                    Amazon_Affiliate_Link={data.Amazon_Affiliate_Link} 
                                    Favorite={data.Favorite}
                                    Timestamp={data.Timestamp}/>
                    })}
            </Grid>
        )
    }
}

export default CurrentRead