import { Box, Container, CssBaseline, Grid, Typography } from '@material-ui/core';
//Material
import { makeStyles } from '@material-ui/core/styles';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import React, { useEffect, useState } from 'react';
import Main_Menu from '../menu-components/Main-Menu.component';




const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
  
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
}));


 


export default function About()
{
    const classes = useStyles();
    const [age, setAge] = useState(23);
    
    useEffect(() => {
        document.title = "About - Johnson Tran"
        calculateAge()
    })

    let calculateAge = () => {
      let today = new Date()
      let dob = new Date('01/23/1999');
      var milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;
      let ageInDays = (today - dob) / milliDay
      let ageInYears = (ageInDays / 365 );
      setAge((Math.round(ageInYears * 10000) / 10000).toFixed(6))
    }


    

    return(
        <React.Fragment>
        <CssBaseline />
        <Grid container >
          <Grid item sm={12} lg={10}>
            <Container maxWidth="md" >
            <Typography component="div">
                <Box textAlign="left" m={1} >
                    <h1>ABOUT ME: <EmojiPeopleIcon></EmojiPeopleIcon></h1>
                </Box>
                <Box textAlign="justify" m={5}>
                
                    <p>I am currently {age} years of age and am interested in the simplest of things. 
                        I attended the University Of Houston and majored in Management Information Systems and
                        Computer Science. Other topics of interest include Finance (mostly about how to utilize leverage and evaluation of value), Mathematics(mostly discrete math, probability, and statistics), Economics(microeconomic and game theory), and Physics. 

                        I also like music, the art of creating things, and am an advocate of long runs and weight training.
                    </p>

                    <br></br>
                    <br></br>


                    <p>"The only true wisdom is in knowing you know nothing.(John SNOw)" - Socrates <br></br>

                        **add Philosophy to the list too  </p>

                </Box>
            </Typography>
            </Container>
          </Grid> 
          <Main_Menu/>
        </Grid>
      </React.Fragment>)
    
}