import React, { Component } from 'react'
import {Paper, Typography, Grid, Container, Link} from '@material-ui/core';
import firebase from '../../Config/Firebase'
import './current-posts.css'


class CurrentPost extends Component 
{
    state = {
        blogPost: []
    }

    componentDidMount()
    {
        firebase.database().ref('Blog').limitToLast(8).once("value").then(snap => {
            snap.forEach(post => {
                this.setState({blogPost: [...this.state.blogPost, post.val()]})
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container direction="column"
                        justify="center" spacing={2}>
                     {this.state.blogPost.map(data => {
                        return <Grid item><Link href={`Blog/${data.Genre}/${data.Title}/${data.id}`}>
                                    <Paper>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item>
                                                <Typography >
                                                    {data.Title}
                                                </Typography>
                                                <Typography>
                                                    {data.Genre}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {data.Timestamp}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            </Link></Grid>
                     })} 
                </Grid>
            </React.Fragment>
        )
    }
}

export default CurrentPost

