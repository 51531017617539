import React, {useEffect} from 'react';

//Material
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Typography, Box, CssBaseline} from '@material-ui/core';
import Main_Menu from '../menu-components/Main-Menu.component'

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    }
  
  }));


export default function About()
{
    const classes = useStyles();

    useEffect(() => {
        document.title = "Travel - Johnson Tran"
        
    })

    

        return(
          <React.Fragment>
            <CssBaseline></CssBaseline>
            <Grid container>
                <Grid item sm={12} lg={10}>
             <Container maxWidth="md">
              <Typography component="div">
                <Box textAlign="left" m={1} >
                    <h1>Travel</h1>
                </Box>
                <Box textAlign="justify" m={5}>

                    
                </Box>
            </Typography>
          </Container>
          </Grid>
          <Main_Menu></Main_Menu>
          </Grid>
          </React.Fragment>
        )
    
}