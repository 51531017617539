import React from 'react'
import {Grid, Box, Container, Typography, CssBaseline, Card, CardActions, CardContent, Link, Paper }  from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import GitHubIcon from '@material-ui/icons/GitHub';


const styles = theme  => ({
    icon: {
      marginRight: theme.spacing(2),
    },
  
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
  });


class ProjectCard extends React.Component
{
    render()
    {
        
        const { classes } = this.props;
        const { Title, Genre, Date, summary } = this.props.data
        return( <Paper elevation={3} variant="outlined" square ><Card className={classes.root}>
           
            <CardContent>
              <Link href={this.props.data.Link} target="_blank">
                <Typography className={classes.title} color="textPrimary" gutterBottom>
                  {Title}
                </Typography>
              </Link>
              <Typography className={classes.pos} color="textSecondary">
                Genre: {Genre}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Date: {Date}
              </Typography>
              <div className="Container" dangerouslySetInnerHTML={{__html: summary}}></div>
              <br></br>
              <Link href={this.props.data.GitHubLink}  target="_blank" color="inherit"><GitHubIcon></GitHubIcon></Link>
            </CardContent>
            
          </Card></Paper>)
    }
}

export default withStyles(styles, { withTheme: true })  (ProjectCard)