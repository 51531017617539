import React, { useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
//components
import Header from '../blog-components/blog-header'




//Grid
import { makeStyles } from '@material-ui/core/styles';
import {CssBaseline, Container,Typography, Box } from '@material-ui/core';



//Firebase
import Main_Menu from '../menu-components/Main-Menu.component'
import Content from '../blog-components/blog-content'



const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
  
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
  }));



export default function Blog()
{  
    const classes = useStyles();

    useEffect(() => {
        document.title = "Blog - Johnson Tran"  
    })

    return(
      <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item sm={12} lg={10}>
        <Container maxWidth="md">
          {/* End hero unit */}
          
          <Typography component="div">
              <Box textAlign="left" m={1} >
                  <h1>BLOG</h1>
              </Box>
          </Typography>
          <Header/>
          <Content/>

        

      </Container>
      </Grid>
        <Main_Menu></Main_Menu>
      </Grid>
    </React.Fragment>
  )   
  
}