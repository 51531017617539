import React, {useState} from 'react'

import Main_Menu_FullScreen from "./Main-Menu-FullScreen.component"
import Main_Menu_Mobile from "./Main-Menu-Mobile.component"

//For Switch Theming
import { createMuiTheme, ThemeProvider,makeStyles  } from "@material-ui/core/styles";
import {Container,Paper, Grid}  from '@material-ui/core';
//Drawer Imports
import useMediaQuery from "@material-ui/core/useMediaQuery"



const Main_Menu= (props) => 
{
  const [darkmode, setDarkMode] = useState(false)
  let theme = createMuiTheme({
      
  })
  
  let handleLightToggle = () => {
    
    setDarkMode(!darkmode)
    window.location.reload(false);
  }
  React.useEffect(() => {
    const mainTheme = localStorage.getItem('backgroundTheme')
    if(mainTheme)
    {
      
      setDarkMode(JSON.parse(mainTheme))
    }
  },[])

  React.useEffect(() => {
    localStorage.setItem('backgroundTheme', JSON.stringify(darkmode))
  })

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <Grid item sm={12} lg={2}>
        {(isMobile)?
          (
            <Main_Menu_Mobile handleLightToggle={handleLightToggle}/>
          ):( 
            <Main_Menu_FullScreen handleLightToggle={handleLightToggle}/>
          )}   
      </Grid>)
}

export default Main_Menu;