import React from 'react'
import Menu_Link from "./Menu-Link.component"
import {Box}  from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fixed_div: {
    position: "fixed",
    top: "1em",
    right: "1em"
}
}));

const Main_Menu= (props) => 
{
    const classes = useStyles();
    return (
    <React.Fragment >
        <Box className={classes.fixed_div}>
          <Menu_Link LightToggle={props.handleLightToggle}></Menu_Link>
        </Box>
    </React.Fragment>
    )
}

export default Main_Menu;
