import React, {Component} from 'react';
import { withStyles } from '@material-ui/styles';

//Json
import BookComponent from '../dumb-components/book.component'

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import Main_Menu from '../menu-components/Main-Menu.component'

import firebase from '../../Config/Firebase'

const styles = theme => ({
      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      }
});


var libraryDataParsed = {"All":[]}
var GenreArray = ["All"]

async function storeBook(data)
{
  
  if(libraryDataParsed[data.Genre] == null)
  {
    libraryDataParsed[data.Genre] = [];
    GenreArray.push(data.Genre)
  }
  libraryDataParsed[data.Genre].push(data)
  libraryDataParsed["All"].push(data)
}


async function createLibraryData()
{
  let firebaseData= null
  await firebase.database().ref('Library').once('value').then(snap=> {
    firebaseData = snap.val()
    snap.forEach(data=> {
      storeBook(data.val())
    })
  })
}



class Library extends Component
{
   state = {
            Genres: ["All"],
            SelectedGenres: "All",
            isMobile: window.matchMedia("(max-width: 600)").matches
        };
        
    async componentWillMount()
    {
       document.title = "Library - Johnson Tran"  
       await createLibraryData();
       this.setState({Genres:GenreArray})
    }
    
    render()
    {
        let { classes } = this.props;
        let booksToShow = libraryDataParsed[this.state.SelectedGenres].map((data) => 
        {
            return(<BookComponent id={data.id} Title={data.Title} Image_Link={data.Image_Link} Author={data.Author} Amazon_Affiliate_Link={data.Amazon_Affiliate_Link} Favorite={data.Favorite} Timestamp={data.Timestamp}></BookComponent>)
        })

        let renderBooks  = (e) =>
        {
            this.setState({SelectedGenres: this.state.Genres[e.target.value]})
            console.log(this.state.SelectedGenres)
            booksToShow=libraryDataParsed[this.state.Genres[e.target.value]].map((data) => 
            {
                return(<BookComponent  id={data.id} Title={data.Title} Image_Link={data.Image_Link} Author={data.Author} Amazon_Affiliate_Link={data.Amazon_Affiliate_Link} Favorite={data.Favorite} Timestamp={data.Timestamp}></BookComponent>)
            })
        }
        
        return(
            <React.Fragment>   
              <CssBaseline />
                <Grid container>
                <Grid item sm={12} lg={10}>
                    <Container maxWidth="md">
                      <Typography component="div" >
                        <Box textAlign="left" m={1} >
                            <h1>LIBRARY:<CollectionsBookmarkIcon></CollectionsBookmarkIcon><CollectionsBookmarkIcon></CollectionsBookmarkIcon></h1>
                            
                            <FormControl className={classes.formControl}>
                              <NativeSelect onChange={e => renderBooks(e)}>
                               {this.state.Genres.map((address, key) => {return <option value={key}>{address}</option>})}
                              </NativeSelect>
                              <FormHelperText>GENRE</FormHelperText>
                          </FormControl>
                        </Box>
                        <Box textAlign="justify" m={5}>
                        <Container className={classes.cardGrid} maxWidth="md">
                            <Grid container spacing = {3}>
                                {booksToShow}
                            </Grid>
                        </Container>
                        </Box>
                    </Typography>
                  </Container>
                </Grid>
                <Main_Menu></Main_Menu>
                </Grid>
            </React.Fragment>           
        )
    }
}

export default  withStyles(styles)(Library);

