import React, { useState } from 'react'
import {Typography, AccordionDetails, Link} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    icon: {
        width: '2px',
        paddingLeft: 2,
    },
});

export default class ArticleDetails extends React.Component 
{
    state = {
        details: []
    }

    componentWillMount() 
    { 
        let tempDetail = []
        for(let key in this.props.articles)
        { 
            tempDetail.push(<AccordionDetails>
                <Typography>
                    <Link href={this.props.articles[key].ArticleLink}  color="inherit">
                        {this.props.articles[key].ArticleTitle}
                        <LaunchIcon className={useStyles.icon} />
                    </Link>
                </Typography>
             </AccordionDetails>)
        }
        this.setState({details:tempDetail})
    }

    render()
    {
        return (
            <React.Fragment>
                {
                 this.state.details
                }
            </React.Fragment>
        )
    }
}
