import React, {Component, component} from 'react'

class RunningDashboard extends Component
{
    render()
    {
        return (
            <div>
                <h1>Running Stats</h1>
            </div>
        )
    }
    
}


export default RunningDashboard;