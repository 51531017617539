import React, { useState,useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {CssBaseline, Container, Typography, 
    Card,CardContent, Box, Grid,
    Button, CardActions,Paper,CardActionArea,CardMedia,Link,Divider}  from '@material-ui/core';
import firebase from '../../Config/Firebase'

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  function Thought()
  {
    const classes = useStyles();
    const [currentThought, setCurrentThought] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      const retrieveThought = async ()=> {
        await firebase.database().ref("Thoughts").limitToLast(1).once("value").then(snap => 
          {
            let fetchData = {}
            snap.forEach(child => {
              fetchData = child.val()
            })
            setCurrentThought(fetchData)
            setLoading(true)
          })
      }
      retrieveThought()
    },[currentThought])

    return (
      <Card item className={classes.root} xs={12}>
        {
         (loading) ? <CardContent>
          <Typography textAlign="center" variant="h2" className={classes.title}>
              Current Thought 
          </Typography>
          <Typography textAlign="center" variant="h2" className={classes.title}>
              Date Published: {currentThought.Timestamp} 
          </Typography>

          {currentThought.VerseLocation}

          <br></br>
          <br></br>
          <Typography variant="h5">
            {currentThought.Title}
          </Typography>
            
          <br></br>
          <Typography variant="h7">
            {currentThought.body}
          </Typography>
          <br></br>
          <Typography variant="h7">
           -{currentThought.Author} 
          </Typography>
          </CardContent>: "Loading Thought......"
        }
      </Card>
       
    
    )
  }

  export default Thought