import React from 'react';

//Material UI\
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

//Icon
import LightBulbButton from '@material-ui/icons/Brightness7';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email'
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 260,
        backgroundColor:  'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(251,64,184,1) 100%)',
      },
    '& > * + *': {
        marginTop: theme.spacing(2),
      }
    
  }));


const Menu = (props) =>
{
    const classes = useStyles();
        return(  
            <Box component="div"  className={classes.root} m={1}>
                <List container component="nav" aria-label="secondary mailbox folders">
                    <ListItem button component="a" href="/">
                        <ListItemText primary="Johnson Tran" />
                    </ListItem>
                    <ListItem button component="a" href="/about">
                        <ListItemText primary="About" />
                    </ListItem>
                    <ListItem button component="a" href="/blog">
                        <ListItemText primary="Blog" />
                    </ListItem>
                    <ListItem button component="a" href="/Library">
                        <ListItemText primary="Library" />
                    </ListItem>
                    <ListItem button component="a" href="/Travel">
                        <ListItemText primary="Travel" />
                    </ListItem>
                    <ListItem button component="a" href="/Work">
                        <ListItemText primary="Work" />
                    </ListItem>
                    <ListItem button component="a" href="/Album">
                        <ListItemText primary="Album" />
                    </ListItem>
                    <ListItem button component="a" href="/Interest">
                        <ListItemText primary="Interest" />
                    </ListItem>
                </List> 
                <Box container direction="row"
                          justify="space-evenly"
                          alignItems="center">
                    <LightBulbButton onClick={props.LightToggle}/>      
                    <Link href="https://www.linkedin.com/in/jtran11/"  target="_blank" color="inherit"><LinkedInIcon></LinkedInIcon></Link>
                    <Link href="https://www.instagram.com/jtranified/"  target="_blank" color="inherit"><InstagramIcon></InstagramIcon></Link>
                    <Link href="https://github.com/jstatus1"  target="_blank" color="inherit"><GitHubIcon></GitHubIcon></Link>

                    <Link href="mailto:johnsontothetran1@gmail.com"  target="_blank" color="inherit"><EmailIcon></EmailIcon></Link>
                    <Link target="_blank" color="inherit"><FacebookIcon></FacebookIcon></Link>
                    <Link href="https://twitter.com/JSON_OBJ"  target="_blank" color="inherit"><TwitterIcon></TwitterIcon></Link>
                    
                </Box>
            </Box> 
        )
}

export default Menu;