import React from 'react'
import {CssBaseline, Container, Grid, Typography,Accordion,AccordionSummary,AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Main_Menu from '../../menu-components/Main-Menu.component'
import SaveIcon from '@material-ui/icons/Save';
import firebase from '../../../Config/Firebase'
import BlogHeader from '../blog-header'

//Article Components
import ArticleDetails from './article-details'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

class ArticlePage extends React.Component
{
    state = {
        ArticleYear : [],
        Articles: {}
    }

    async grabData()
    {

        await firebase.database().ref("Articles").once("value").then(snap => {
            this.setState({Articles:snap.val()})
            snap.forEach(year=> {
                this.setState({ArticleYear:[...this.state.ArticleYear,year.key]})
               
            })
        })
    }

    componentWillMount()
    {
        this.grabData()
    }

    render(){
        return(
            <React.Fragment>
                <CssBaseline />
                <Grid container >
                    <Grid item sm={12} lg={10}>
                    <Container maxWidth="md">
                        <Typography variant="h2">ARTICLES<SaveIcon/></Typography>
                        <BlogHeader></BlogHeader>
                        {
                            
                           this.state.ArticleYear.map(data=> {
                               
                               return(
                               <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                <Typography className={useStyles.heading}>{data}</Typography>
                                </AccordionSummary>
                                    <ArticleDetails articles={this.state.Articles[data]}/>
                                </Accordion>)
                           })
                        }
                        
                    </Container>
                </Grid>
                    <Main_Menu></Main_Menu>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ArticlePage