import React from 'react'
import RouterComponent from "./components/router.component"
import firebase from "./Config/Firebase"

let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: "johnsontran.tech",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: "johnsonwebsite-98fa7.appspot.com"
};

class App extends React.Component 
{ 
  componentWillMount()
  {
    firebase.initializeApp(config)
  }

  render()
  {
    return (
      <RouterComponent/>
    )
  }
  
}

export default App;