import React, { Component } from 'react'
import {CssBaseline, Typography, Container, Grid, Box, Paper}  from '@material-ui/core';
import Main_Menu from '../menu-components/Main-Menu.component'
import Blog_NavBar from '../blog-components/blog-header'
import firebase from '../../Config/Firebase'
import ReactMarkdown from 'react-markdown';

export class BlogPage extends Component {

    state ={
        blogPost: [],
        loading: true
    }


    componentDidMount()
    {
        firebase.database().ref(`Blog/${this.props.id}`).once("value").then(snap => {
            this.setState({blogPost: snap.val() })
        }).then(
            () => {
                this.setState({loading: false})
            }
        )
    }

    getPost()
    {
       
    }



    render() {
        return (
            <React.Fragment>
                <CssBaseline></CssBaseline>

                <Grid container>
                        <Grid item sm={12} lg={10}>
                        <Container maxWidth="md">
                        <Blog_NavBar/>
                            <Typography component="div" variant="h3">
                                <Box textAlign="left" m={1} >
                                    {this.props.Title}
                                </Box>
                            </Typography>
                            <Typography component="div" variant="h10">
                                
                                <Box textAlign="left" m={1} >
                                    Genre:
                                    {this.props.Genre}
                                </Box>
                            </Typography>
                            <Typography component="div" variant="h10">
                                <Box textAlign="left" m={1} >
                                    {this.state.blogPost.Timestamp}
                                </Box>
                            </Typography>
                                <Box textAlign="justify" m={5}>
                                    <ReactMarkdown components={{
                                        // Map `h1` (`# heading`) to use `h2`s.
                                        // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                        img: ({node, ...props}) => <img style={{display: "block", marginLeft: "auto", 
                                                                        marginRight: 'auto',width: '50%'}} 
                                                                        {...props} />
                                        }}>
                                        {this.state.blogPost.Content}
                                    </ReactMarkdown>
                                </Box>
                        </Container>
                    </Grid>



                  
                <Main_Menu/>
                </Grid>
            </React.Fragment>
        )
    }
}

export default BlogPage
