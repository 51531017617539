import React, {Component} from 'react';

//Material UI Import
import { withStyles } from '@material-ui/styles';
import {Grid, Typography, Link, Container} from '@material-ui/core';

import FitnessHighlighDisplay from './fitness-highlight-display.content'
//This is just the page that displays an overview of fitness activities

//Get Strava Stats

const styles = theme => ({
  
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  cardMedia: {
    textAlign: 'center',
    fontSize: '1 rem',
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  }
});

class FitnessTable extends Component {
  state = {
    all_run_totals:[]
  }

  async getRunningStats(){
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    }
    
    const body = JSON.stringify({
        client_id: process.env.REACT_APP_STRAVA_CLIENT_ID,
        client_secret: process.env.REACT_APP_STRAVA_SECRET,
        refresh_token: process.env.REACT_APP_STRAVA_REFRESH_TOKEN,
        grant_type: 'refresh_token'
    })
  
    const reauthorizeResponse = await fetch('https://www.strava.com/api/v3/oauth/token', {
        method: 'post',
        "headers": headers,
        "body": body
    })
  
    const reAuthJson = await reauthorizeResponse.json()
    
    const responseStats = await fetch('https://www.strava.com/api/v3/athletes/72540105/stats?access_token=' + reAuthJson.access_token)
   
    const jsonStats = await responseStats.json()
    this.setState({all_run_totals:jsonStats.all_run_totals})
    console.log(this.state.all_run_totals)
  }

  componentWillMount(){
    this.getRunningStats();
  }

  


  render()
  {
    let { classes } = this.props;

    let updateRunningDiplay = 
        <React.Fragment>
          <FitnessHighlighDisplay classes={classes} id={1} Title="Total Runs" Content={this.state.all_run_totals.count}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={2} Title="Total Distance" Content={(this.state.all_run_totals.distance * 0.000621371).toFixed(2) + " miles"}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={3} Title="Total Runs Time" Content={Math.floor(this.state.all_run_totals.elapsed_time/3600)+"hr " + Math.floor((this.state.all_run_totals.elapsed_time-(Math.floor(this.state.all_run_totals.elapsed_time/3600)*3600))/60)+ "min"}>
          </FitnessHighlighDisplay>
        </React.Fragment>

      let updatePRDiplay = 
      <React.Fragment>
        <FitnessHighlighDisplay classes={classes} id={4} Title="Bench" Content={225 + "Ibs"}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={5} Title="Deadlift" Content={325 + "Ibs"}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={6} Title="Squat" Content={245+ "Ibs"}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={4} Title="Overhead Press" Content={135+"Ibs"}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={5} Title="Pull Ups" Content={25}>
          </FitnessHighlighDisplay>
          <FitnessHighlighDisplay classes={classes} id={6} Title="Dips" Content={25}>
          </FitnessHighlighDisplay>
      </React.Fragment>

    return (
      <div>
        <Link href='/Stats/RunningData' target="_blank" color="inherit">
          <Typography variant="h6">Daily Running Stats</Typography>
        </Link>
          <Typography variant="caption" display="block" gutterBottom>
              **Started Recording 11/28/2020
          </Typography>

        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing = {3}>
            {updateRunningDiplay}
          </Grid>
        </Container>
        
        <Link href='/' target="_blank" color="inherit">
          <Typography variant="h6">Marathons</Typography>
        </Link>
        <Link href='/' target="_blank" color="inherit">
          <Typography variant="h6">Iron Mans</Typography>
        </Link>
        <Link href='/' target="_blank" color="inherit">
          <Typography variant="h6">P.R.</Typography>
        </Link>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing = {3}>
            {updatePRDiplay}
          </Grid>
        </Container>
        
      </div>
    );
  }
  
}

export default withStyles(styles, { withTheme: true })(FitnessTable);