import React, {useEffect} from 'react';
//Material
import {CssBaseline, Container, Typography, 
    Card,CardContent, Box, Grid,
    Button, CardActions,Paper,CardActionArea,CardMedia,Link,Divider}  from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import Main_Menu from '../menu-components/Main-Menu.component'
import Thought from '../home-components/home-thought'
import CurrentReads from '../home-components/current-reads'
import CurrentPosts from '../home-components/current-posts'


function Home()
{
    useEffect(() => {
        document.title = "Home - Johnson Tran"
        
    })

     return(
        <React.Fragment>
        <CssBaseline></CssBaseline>
        <Grid container>
            <Grid item sm={12} spacing={3}>
                    <Container maxWidth="md">
                      <Typography component="div" >
                        <Box textAlign="center" m={1} >
                            <HomeIcon/><h1>LOS PENSAMIENTOS DE JOHNSON </h1>
                        </Box>
                     </Typography>
                     <Divider></Divider>

                    <br></br>
                    <br></br>
                     <Thought/>
                    <br></br>
                    <br></br>
                    <Divider></Divider>

                    <br></br>
                    <Typography component="div" variant="h5">
                        <Box textAlign="left" m={1} >
                            Last Post:
                        </Box>
                     </Typography>
                     <CurrentPosts/>

                     <br></br>
                    <br></br>
                    <Divider></Divider>

                    <br></br>
                    <Typography component="div" variant="h5">
                        <Box textAlign="left" m={1} >
                            Current Reads:
                        </Box>
                     </Typography>
                     <CurrentReads/>


                  </Container>
            </Grid>

            <Main_Menu></Main_Menu>
         </Grid>
      </React.Fragment>)
    
}

export default  Home;